<template>
    <a-layout>
        <a-layout-sider style="background-color: #FFF;">
            <div style="width:90%;overflow: hidden;text-overflow: ellipsis;">
                <a-dropdown>

                    <a-menu slot="overlay" v-if="getOrganId() == 0">
                        <a-menu-item v-for="item in getOrganList()" :key="item.organId" @click="selectOrganId">
                            <a>{{ item.name }}</a>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <a-tree show-line showIcon :treeData="depData"
                        :replaceFields="{ children: 'children', title: 'name', key: 'depId' }" @select="onSelect">
                </a-tree>
            </div>
        </a-layout-sider>
        <a-layout-content style="background-color: #FFF;">
            <div class="content-header">
                <a-affix :offset-top="0" style="width: 100%;">
                    <a-row type="flex" justify="space-between">
                        <a-col span="4">
                            <a-input-search v-model="param.keyword" @pressEnter="doRefresh" allowClear search
                                            placeholder="关键词查询"
                                            @change="() => { if (param.keyword == '') { doRefresh(); } }"></a-input-search>

                        </a-col>
                        <a-col span="12">
                            <a-row type="flex" align="middle" justify="end" :gutter="3">
                                <a-col span="12">
                                    <a-select v-model="organId" style="width:100%" placeholder="根据单位筛选">
                                        <a-select-option value="">所有单位</a-select-option>
                                        <a-select-option :value="organ.organId" v-for="(organ, index) in organList"
                                                         :key="index">{{
                                                             organ.name }}</a-select-option>
                                    </a-select>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-affix>
            </div>
            <a-table :scroll="{ x: '100%' }" :pagination="false" :style="{ background: '#FFF' }" size="small"
                     row-key="staffId" border :columns="keys" ref="list" :data-source="userData.records">
                <!--操作-->
                <template slot-scope="row" slot="action">

                </template>
            </a-table>
            <a-row>
                <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;background-color: #FFF;">
                    <a-pagination :defaultPageSize="param.limit" :show-total="total => `合计 ${total} 条数据`" size="small"
                                  :total="userData.total" @change="getNext" @showSizeChange="setLimit"
                                  :current="userData.current" />
                </a-col>
            </a-row>
        </a-layout-content>
    </a-layout>
</template>

<script>
import utils from '@/common/utils';
import request from '@/config/request';
import apiUtil from '@/common/apiUtil';
export default {
    data() {
        return {
            depData: [],
            userData: [],
            organId: apiUtil.getDefaultOrganId(),
            param: {
                page: 1,
                limit: 20,
                depId: '',
                keyword: ''
            },
            organList: apiUtil.getOrganList(),
            keys: [
                { title: 'ID', dataIndex: 'staffId', width: 100, align: 'center' },
                { title: '姓名', dataIndex: 'name', align: 'left', width: 100, ellipsis: true },
                { title: '部门', dataIndex: 'depName', align: 'center', width: 160, ellipsis: true },
                { title: '手机号', dataIndex: 'phone', align: 'center', width: 160, ellipsis: true },
                { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
            ],
        };
    },
    mounted() {
        this.getDepList();
        this.getUserList();
    },
    computed: {
        getOrganName() {
            return apiUtil.getDefaultOrganName(this.organId);
        }
    },
    watch: {
        organId(e) {
            this.param.page = 1;
            this.param.organId = e;
            this.param.depId = '';
            this.getDepList();
            this.getUserList();
        }
    },
    methods: {
        ...apiUtil,
        selectOrganId(e) {
            this.organId = e.key;
        },
        // 获取通讯录部门列表
        getDepList() {
            request.get("/platform/depart/scope-list", { organId: this.organId, isAll: 1 }).then(ret => {
                // console.log(ret.data);
                this.depData = ret.data;
            });
        },
        doRefresh() {
            this.param.page = 1;
            this.getUserList();
        },
        //获取人员列表
        getUserList() {
            utils.showSpin();
            request.get("/platform/staff/scope-list", this.param).then(ret => {
                // console.log(ret.data);
                this.userData = ret.data;
            });
        },
        onSelect(selectedKeys) {
            // console.log(selectedKeys);
            this.param.depId = selectedKeys;
            this.param.page = 1;
            this.getUserList();
        },

        //设置每页的显示条数
        setLimit(current, limit) {
            this.param.page = 1;
            this.param.limit = limit;
            this.doRefresh();
        },
        //页码切换
        getNext(page) {
            this.param.page = page;
            this.getUserList();
        }
    }
};
</script>

<style >
.ant-tree li .ant-tree-node-content-wrapper {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>